import { role } from "@/models/User";
import { get } from "lodash";
import { getCompany, isAuth } from "../actions/auth";

export const isFeatureEnabled = (feature: Feature): boolean => {
  return get(getCompany(), `crmConfig.${feature}`, false);
};

export const getFeatureCompany = <T>(feature: Feature, defaultValue: T): T => {
  return get(getCompany(), `crmConfig.${feature}`, defaultValue) as T
}

export const isTheRolEnabled = (roles: role[] | role, externalUser?: any, field?: any, operator: 'in' | 'every' = 'in') => {
  const rolesOfUser: role[] = get(isAuth(), 'roles', [])
  const externalRoles: role[] = get(externalUser, field || '', [])
  
  if(operator == 'every') return [roles].flat().every((rol: role) => externalUser ? externalRoles.includes(rol) : rolesOfUser.includes(rol))

  return [roles].flat().some((rol: role) => externalUser ? externalRoles.includes(rol) : rolesOfUser.includes(rol))
}

export const isPersonalFeatureEnables = (feature: PersonalFeature): boolean => {
  return get(isAuth(), `config.${feature}`, false)
}

export const getPersonalFeature = <T>(feature: PersonalFeature, defaultValue: T): T => {
  return get(isAuth(), `config.${feature}`, defaultValue)
}

export const getAppearance = <T>(field: AppearanceConfig, defaultValue?: T): T => {
  return get(isAuth(), `appearanceConfig.${field}`, defaultValue)
}

export const isEnabledRolForCreateCompanyGroup = () => {
  if (!isFeatureEnabled(Feature.ENABLE_BUSINESS_SALES_MODEL)) return false;
  const { roles = [] } = get(getCompany(), `crmConfig.${Feature.CREATE_COMPANY_GROUP}`, { roles: [] });
  if (roles[0] === 'all') return true;

  const userRoles: role[] = get(isAuth(), 'roles', [])
  if (userRoles.includes('comercial')) return true;

  return userRoles.some(role => roles.includes(role))
}

export enum AppearanceConfig {
  DEFAULT_CHAT_ACTION = 'defaultChatAction',
  DISABLE_CONTACT_PHOTO = 'disableContactPhoto'
}

export enum PersonalFeature {
  PERSONAL_EMAIL = "enablePersonalEmail",
  PERSONAL_WHATSAPP = "enablePersonalWhatsapp",
  PERSONAL_MAP = "mapEnabled",
  PERSONAL_INBOX = "enableInbox",
  COLLECT_LOCATION_MODE = 'collectLocationMode',
  CONTACT_FORM_TO_USE = 'contactFormToUse',
  DISABLE_NOTIFY_WHATSAPP_MESSAGE = 'disableNotifyWhatsappMessage',
  DISABLE_REQUEST_SALE = 'disableRequestSale'
}

export enum Feature {
  CLOSE_SALES = "closeSaleEnabled",
  CHANNELS = "enableChannels",
  UNOFFICAL_WHATSAPP = "enableUnofficalWhatsapp",
  LOCATION_FIELD = "enableLocationField",
  LOCATION_REQUIRED = "locationFieldObligatory",
  INBOX = "enableInbox",
  SHOW_ALL_SELLERS_IN_TRANSFER = "enableShowAllSellersInTransfer",
  SOCIAL_ICONS = "enableSocialIcons",
  DISABLE_LASTNAME = "disableLastName",
  DISABLE_EMAIL = "disableEmail",
  ENABLE_PERSONAL_EMAIL = "enablePersonalEmail",
  DUPLICATE_PHONES_GROUP = "enableDuplicatePhonesOnGroup",
  DISABLE_WHATSAPP_LINK = "disableWhatsapp",
  PERSONAL_WHATSAPP_CREATE_CONTACT = "personalWhatsappCreateContact",
  PERSONAL_EMAIL = "enablePersonalEmail",
  ENABLE_BUSINESS_SALES_MODEL = "enableBusinessSalesModel",
  ENABLE_SUB_CONVERSATIONS = 'enableSubConversations',
  DISABLE_UNARCHIVING_BY_CLIENT_INTERACTION = 'disableUnarchivingByClientInteraction',
  SHOW_ALL_AGENTS_IN_TRANSFER_FOR_ALL_USERS = 'showAllAgentsInTransferForAllUsers',
  VISIT_INTERACTION = 'visitInteraction',
  CREATE_COMPANY_GROUP = 'createCompanyGroup',
  ENABLE_MEETINGS = 'enableMeetings',
  ENABLE_GOV_ID = 'enableGovId',
  CONTACT_FORM_TO_USE = 'contactFormToUse',
  DISABLE_MANDATORY_FIELDS_IN_COMPANIES = 'disableMandatoryFieldsInCompanies',
  CHECK_CLOSING_REQUEST_SALE = 'checkClosingRequestSale',
  ENABLE_REQUEST_SALE = 'enableRequestSale',
  ENABLE_COMPANY_GROUP_SECTORS = 'enableCompanyGroupSectors',
  ENABLE_NET_PROMOTER_SCORE = 'enableNetPromoterScore',
  ENABLE_CAMPAIGNS = 'campaigns.isActive',
  ENABLE_COMISSIONS_FEATURE = 'comissions.enabled',
  ENABLE_MANUAL_SALES = 'manualSales.active',
  ENABLE_INTERNAL_PHONE_NUMBERS = 'enableInternalPhoneNumbers',
  ENABLE_GENERATE_SALE_BY_TAG = 'enableGenerateSaleByTag'
}

export enum ROLES {
  USER = 'user',
  SUPER = 'super',
  COMERCIAL = 'comercial',
  ADMIN = 'admin',
  USER_MANAGER = 'users_manager',
  PROJECT_MANAGER = 'project_manager'
}

export enum PostHogFeature {
  B2B_INFORMATION_SHEET = 'b2b-information-sheet'
};

